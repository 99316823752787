import Glide from '@glidejs/glide'
import {DOM} from "./_variables";

export default {
    galleries: () => {

      const sliders = document.querySelectorAll('.glide');

      if (sliders.length) {
        for (const slider of sliders) {

          const glide = new Glide(slider, {
            type: 'carousel',
            perView: 2,
            focusAt: 0,
            startAt: 0,
            gap: 40,
            peek: {
              before: 0,
              after: 70
            },
            breakpoints: {
              600: {
                perView: 1,
                gap: 20,
                peek: {
                  before: 0,
                  after: 100
                }
              }
            }
          })

          glide.mount()
        }

      }
    }
}
