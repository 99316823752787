import Marquee3k from 'marquee3000';

export default {
    marquee: () => {
        const marqueeSelector = document.querySelectorAll('.marquee3k');

        if(marqueeSelector) {
            Marquee3k.init({
                selector: 'marquee3k',
            });
        }
    }
}
